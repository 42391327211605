.radio-button-container {
  /* padding-bottom: 8px; */
  /* border-bottom: 1px solid rgb(210, 210, 210); */
}

.radio-button-itens {
  padding-top: 8px;
}

/* Customize the label (the container) */

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.container > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a green background */

.container input:checked ~ .checkmark {
  background-color: rgb(0, 127, 133);
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}