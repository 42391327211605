@font-face {
  font-family: 'UbuntuBold';

  src: url(/static/media/Ubuntu-Bold.d0fc3085.ttf) format('ttf');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'UbuntuMedium';

  src: url(/static/media/Ubuntu-Medium.335cc443.ttf) format('ttf');

  font-weight: normal;

  font-style: normal;
}