.support-dropdown-contents::before,
.support-dropdown-contents::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: calc(50% - 11px);
  border: 11px solid transparent;
  border-bottom-color: #fff;
}

.support-dropdown-contents {
  margin-top: 1rem;
}

.user-dropdown-contents {
  margin-top: 1rem;
  width: 116px;
}

.user-dropdown-contents::before,
.user-dropdown-contents::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 3rem;
  border: 9px solid transparent;
  border-bottom-color: #fff;
}

#publish-button:disabled {
  cursor: not-allowed;
  opacity: 50%;
}

.publish-button {
  /* NVA Green */
  background: #2BA63B;
  /* Elevation/Dark Fill/3 */
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  padding: 10px 48px;
  color: white
}

.publish-button:hover {
  color: #2BA63B;
}

.bg-mainNVAcolor {
  background-color: #006F85
}
