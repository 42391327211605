div[class^='FormFooter-sc'] {
  padding: 20px 0;
}

.staff-save-button:active {
  background-color: #013639 !important;
}

.staff-save-button.show-error-outline {
  border: solid 1px #DC2626;
  outline: none;
}