.simpleitem-container {
  /* display: 'grid';
  grid-template-columns: '1fr 3fr';
  margin-bottom: '20px'; */
  cursor: pointer;
  /* padding: 10px;
  align-items: center; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  margin-bottom: 16px;
  gap: 16px;
  width: 100%;
  /* Teal/600 *Main Color */
  background: #006F85;
  /* Elevation/Light Fill/1 */
  box-shadow: 0px 1px 2px rgba(142, 142, 142, 0.3), 0px 1px 3px 1px rgba(142, 142, 142, 0.15);
  border-radius: 4px;
}

.simpleitem-container.isDisabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 50%;
}

.services-list {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.icons-list {
  display: flex;
  align-self: flex-start;
  gap: 4px;
  cursor: pointer;
}

.simpleitem-text {
  color: white;
}

.show-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.simpleitem-container img {
  min-height: 48px;
}

.hide-outline:focus {
  outline: none;
}

.selected-card {
  border: solid 1px rgba(36, 193, 225, 1);
}