.card-text-container {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
}

.card-text-container.card-text-container-mobile {
  position: relative;
}

.card-text {
  padding: 0px;
  margin-left: 135px;
  transform: translateY(50%);
  width: 53%;
  text-align: left;
  color: rgb(57, 59, 60);
  position: absolute;
  bottom: 50%;
}

.card-text.card-text-mobile {
  padding: 30px 35px;
  text-align: center;
  width: 100%;
  margin-left: 0;
  transform: none;
  position: static;
}

.card-text.is-dark :where(.card-headline, .card-rich-text p) {
  color: rgb(57, 59, 60);
  text-shadow: 0 1px 4px rgb(250 250 250 / 41%);
}

.card-headline {
  margin-bottom: 20px;
  color: rgb(249, 245, 239);
  text-shadow: rgb(0 0 0 / 41%) 0px 1px 4px;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 64px;
  letter-spacing: 5.4px;
}

.card-headline.card-headline-mobile {
  color: rgb(57, 59, 60);
  text-shadow: none;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: 4px;
}

.card-rich-text p {
  margin-bottom: 20px;
}

.card-rich-text-mobile {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.3px;
}

.card-rich-text-mobile h3{
  font-size: 18px;
}

.card-rich-text-desktop * {
  color: rgb(249, 245, 239);
  text-shadow: rgb(0 0 0 / 41%) 0px 1px 4px;
}

.cta-label {
  display: inline-block;
  font-family: "UbuntuMedium", sans-serif;
  /* border-radius: 100px; */
  color: rgb(255, 255, 255);
  padding: 20px 35px;
}

.cta-label-mobile {
  padding: 15px 40px;
  margin-bottom: 12px;
}

.preview-card__iframe-wrapper {
  height: 567px;
}

@media (max-width: 1600px)  {
  .preview-card__iframe-wrapper {
    height: 500px;
  }
}

@media (max-width: 1280px)  {
  .preview-card__iframe-wrapper {
    height: 450px;
  }
}

@media (max-width: 1100px)  {
  .preview-card__iframe-wrapper {
    height: 320px;
  }
}