.new-button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.25rem;
  padding: 22px;
  gap: 6px;
  width: 100%;
  /* Teal/600 *Main Color */
  background: #006F85;
  /* Elevation/Light Fill/1 */
  box-shadow: 0px 1px 2px rgba(142, 142, 142, 0.3), 0px 1px 3px 1px rgba(142, 142, 142, 0.15);
  border-radius: 4px;
}