.modal {
  /* align-items: center!important;
  background: url('../../static/images/modal-bg.png') no-repeat!important;
  border-radius: 3px;
  display: flex!important;
  height: 538px!important;
  justify-content: center!important;
  margin: 1.75rem auto;
  position: relative;
  width: 530px!important;
  z-index: 100;
  top: 11rem; */
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 80%, #006F85 80%);
  border-radius: 3px;
  height: 80vh;
  margin: 0 auto;
  position: relative;
  width: 80%;
  z-index: 100;
  top: 5rem;
  margin-top:4rem;
}

.rw-modal-body {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  background: #fff;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  border: none;
  color: white;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 2%;
  top: 5%;
}

.publish-modal-footer {
  top: 9.5rem;
}

/* .rw-action-log-table > div > div {
  height: 22rem;
} */

.MuiIcon-root {
  display: none;
}

.MuiTableSortLabel-root {
  cursor: default;
}

.MuiTableSortLabel-root:hover {
  color: initial
}

.subheading-text {
  font-size: 1.05em;
}

