.location-container {
  background: rgba(161, 207, 84, 1);
  /* border-top-right-radius: 20px; */
}

.heading-text {
  color: rgba(23, 53, 59, 1);
}

.selected-site-text {
  color: rgba(0, 0, 0, 1);
}

.text-location-link {
  color: rgba(0, 0, 0, 1);
}

div[class^="FieldsGroup-sc"]{
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.dropdown {
  width: 100%;
  left: 0;
  --dropdown-button: 64px;
  top: var(--dropdown-button);
  max-height: calc(100% - var(--dropdown-button));
}

.dropdown-item:hover {
  background: rgba(161, 207, 84, 0.3);
}