.btn { position: fixed; bottom: 1.5rem; right: 1.5rem; --bg-opacity: 1; background-color: #ffffff; background-color: rgba(255, 255, 255, var(--bg-opacity)); box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); border-radius: 9999px;
}

.btn-hover { transition-property: all; --transform-translate-x: 0; --transform-translate-y: 0; --transform-rotate: 0; --transform-skew-x: 0; --transform-skew-y: 0; --transform-scale-x: 1; --transform-scale-y: 1; transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)); transition-duration: 150ms; transition-timing-function: cubic-bezier(0.4, 0, 1, 1); display: flex; --transform-scale-x: 1.25; --transform-scale-y: 1.25; box-shadow: 0 0 0 3px rgba(118, 169, 250, 0.45);
}

.help-button { position: fixed; bottom: 1.5rem; right: 1.5rem; --bg-opacity: 1; background-color: #ffffff; background-color: rgba(255, 255, 255, var(--bg-opacity)); box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); border-radius: 9999px;
}

.help-button:hover { transition-property: all; --transform-translate-x: 0; --transform-translate-y: 0; --transform-rotate: 0; --transform-skew-x: 0; --transform-skew-y: 0; --transform-scale-x: 1; --transform-scale-y: 1; transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)); transition-duration: 150ms; transition-timing-function: cubic-bezier(0.4, 0, 1, 1); display: flex; --transform-scale-x: 1.25; --transform-scale-y: 1.25; box-shadow: 0 0 0 3px rgba(118, 169, 250, 0.45);
}

.help-button {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 9999px;
  border: solid white 2px;
}

.contact-modal {
  /* align-items: center; */
  display: flex;
  max-height: 80vh;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 65vw;
  min-width: 530px;
  z-index: 100;
  /* top: 5rem; */
}

.success-modal {
  align-items: center;
  background: rgb(255,255,255);
  display: flex;
  height: 470px;
  justify-content: center;
  margin: 1.75rem auto;
  position: relative;
  width: 530px;
  z-index: 100;
  top: 5rem;
}

.contact-modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  border: none;
  color: white;
  cursor: pointer;
  border: none;
}

.contact-modal-footer {
  top: 5.5rem;
}
