@font-face {
  font-family: 'Rubik';

  src: url(/static/media/Rubik-Regular.6c980940.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'LatoBlack';

  src: url(/static/media/Lato-Black.77d35374.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'GoudyOldStyleBold';

  src: url(/static/media/GoudyOldStyle-Bold.01045b5e.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'GoudyOldStyleMT';

  src: url(/static/media/GoudyOldStyleMT.b5238b03.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'LoraRegular';

  src: url(/static/media/Lora-Regular.c87345ce.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'RalewayBold';

  src: url(/static/media/Raleway-Bold.575e4317.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'CourgetteRegular';

  src: url(/static/media/Courgette-Regular.909ea532.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'OpenSansSemiBold';

  src: url(/static/media/OpenSans-Semibold-webfont.dc95b864.woff) format('woff');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'MerriweatherBold';

  src: url(/static/media/Merriweather-Bold.d9f26381.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'CabinBold';

  src: url(/static/media/Cabin-Bold.f89922f3.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'MontserratRegular';

  src: url(/static/media/Montserrat-Regular.ee653992.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'MontserratMedium';

  src: url(/static/media/Montserrat-Medium.a98626e1.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'UbuntuBold';

  src: url(/static/media/Ubuntu-Bold.d0fc3085.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'UbuntuMedium';

  src: url(/static/media/Ubuntu-Medium.335cc443.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'CormorantMedium';

  src: url(/static/media/Cormorant-Medium.8c9268ea.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'MontserratSemiBold';

  src: url(/static/media/Montserrat-SemiBold.c88cecbf.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'AbrilFatfaceRegular';

  src: url(/static/media/AbrilFatface-Regular.99ad7968.otf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'LatoRegular';

  src: url(/static/media/Lato-Regular.7f690e50.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'LatoBlack';

  src: url(/static/media/Lato-Black.77d35374.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'FjallaOneRegular';

  src: url(/static/media/FjallaOne-Regular.a92ec201.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'ArvoBold';

  src: url(/static/media/Arvo-Bold.2d1d6d49.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'CopseRegular';

  src: url(/static/media/Copse-Regular.99f89ca1.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'NunitoBold';

  src: url(/static/media/Nunito-Bold.a8c930b0.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'SourceSansProSemiBold';

  src: url(/static/media/SourceSansPro-SemiBold.774c05cc.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'SourceSansProBold';

  src: url(/static/media/SourceSansPro-Bold.0d9b62a0.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'AlegreyaSansBold';

  src: url(/static/media/AlegreyaSans-Bold.d2f2e863.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'QuicksandBold';

  src: url(/static/media/Quicksand-Bold.0c044626.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'WorkSansLight';

  src: url(/static/media/WorkSans-Light.38088e7c.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'WorkSansRegular';

  src: url(/static/media/WorkSans-Regular.92bbabfd.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'RubikRegular';

  src: url(/static/media/Rubik-Regular.6c980940.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;
}