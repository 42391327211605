.drag-container {
  /* display: 'grid';
  grid-template-columns: '1fr 3fr';
  margin-bottom: '20px'; */
  cursor: pointer;
  /* padding: 10px;
  align-items: center; */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  margin-bottom: 16px;
  gap: 16px;
  width: 100%;
  /* Neutral/50 */
  background: #F9FAFB;
  /* Elevation/Light Fill/1 */
  box-shadow: 0px 1px 2px rgba(142, 142, 142, 0.3), 0px 1px 3px 1px rgba(142, 142, 142, 0.15);
  border-radius: 4px;
}

.services-list {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.icons-list {
  display: flex;
  align-self: flex-start;
  gap: 4px;
  cursor: pointer;
}

.menu-popup {
  position: fixed;
  padding: 0px;
  min-width: 220px;
  /* Palette/Blue Gray/50 */
  background: #F8FAFC;
  /* Elevation Light/1 */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 9px 16px;
}

.menu-item-container:hover{
  /* background: #DBEAFE; */
  background: rgba(161, 207, 84, 0.3)
}

.menu-item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
  /* Palette/Blue Gray/700 */
  color: #334155;
}

.menu-item-disabled {
  color: #D1D5DB;
}

.hide {
  display: none;
}

.show-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.drag-container img {
  min-height: 48px;
}

.drag-container:hover{
  background: rgba(161, 207, 84, 0.3)
}

.hide-outline:focus {
  outline: none;
}

.selected-card {
  border: solid 1px rgba(36, 193, 225, 1);
  background: rgba(36, 193, 225, 0.2);
  color: #006F85;
}

.grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}